import React, {useEffect, useState, createElement} from "react"
import view from "./blog.module.scss";
import { Link } from "gatsby"
import {FacebookShareButton,  TwitterShareButton, FacebookIcon, XIcon} from "react-share";

const Blog = (props) => {
  const {data, title} = props;
  const location = typeof window !== 'undefined' && window.location
  const shareLink = location.href;
  const blogCount = data.blogposts.length ;
  // console.log(data.blogposts);
  useEffect(()=> {

    let blogPTags = document.getElementsByTagName("p");
    for(let i = 0; i < blogPTags.length; i++){
      if(blogPTags[i].innerHTML === "" || blogPTags[i].innerHTML === null){
        blogPTags[i].setAttribute("class","rich-text-inline");
      }
    }
    var paraClasses = document.querySelectorAll(".rich-text-inline");
    for (let i = 0; i < paraClasses.length; i++) {
      paraClasses[i].style.height = "25px";
    }
  })

  function handleThumbnailAltText() {
    if (!data.thumbnailAltText || data.thumbnailAltText === "") {
      return `${title} Blog Image`;
    } else {
      return data.thumbnailAltText;
    }
  }

  return(
    <>


      <div className={`${view['blog-main-content']}`}>
        {title? <h1 style={{marginBottom:0}}>{title}</h1> : null }
        {data.publicationDate? <p style={{fontStyle:"italic", marginTop:5}}>Published: {data.publicationDate}</p> : null}

        <div className={`${view['blog-underline']}`}></div>

        {/**SHARE GOES HERE */}
        <FacebookShareButton style={{marginRight:8}} url={`${shareLink}`}>
          <FacebookIcon 
            size={32}
            round
            />
        </FacebookShareButton>
        <TwitterShareButton  url={`${shareLink}`}>
          <XIcon iconFillColor='black' bgStyle={{ fill: 'white' }}
            size={32}
            round
            />
        </TwitterShareButton>
        {data.thumbnailImage?
          <div style={{width:'100%', maxWidth:750, margin:'0 auto'}}>
            <img className={`${view['blog-main-thumbnail']}`} src={data.thumbnailImage} alt={handleThumbnailAltText()}/>
          </div>
          : 
          null
        }
        <div className={`${view['rich-para-cont']}`} style={{fontSize: 15, lineHeight:1.8}} dangerouslySetInnerHTML={{ __html: data.blogContent}}></div>
        <div className={`${view['tags']}`}>
        <p>
         {blogCount > 0?<>Posted in:&nbsp;</>:<></>}
          {data.blogposts.map((tags, index)=>{
            const tagNameLower = tags?.tag?.toLowerCase();
            if(tags?.tag == 'undefinded'){
              return(
                <></>
              ) }

            return(
              <>
              {blogCount > 1 ?
                <>
                  {index === blogCount - 1? <span>and&nbsp;</span> : <></>}
                    <Link href={`/categories/${tagNameLower}`}>{tags.tag}</Link>
                  {index === blogCount - 1?<></>:<span>,&nbsp;</span>}  
                </>
                :
                <>
                  <Link href={`/categories/${tagNameLower}`}>{tags?.tag}</Link>&nbsp;
                </>
              }
              </>
            )
          })}
        </p>
      </div>
      </div>

    </>
  )
}

export default Blog;